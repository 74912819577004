import React from "react";
import Layout from "../components/Layout";
import ContactSection from "../components/sections/ContactSection";
import Section, { SectionContent } from "../components/sections/Section";
import { CopyrightLink, H2 } from "../components/Common";
import styled from "styled-components";
import { graphql, StaticQuery } from "gatsby";
import { GatsbyImage as Img } from "gatsby-plugin-image";
import ReactHelmet from "react-helmet";
import Header from "src/elements/Header";
import Footer from "src/elements/Footer";
import theme from "src/components/theme";

const DuckImage = styled(Img)`
  position: absolute !important;
  left: 764px;
  bottom: 0;
  display: none !important;

  @media (min-width: 800px) {
    display: inline-block !important;
  }
`;

const SectionContainer = styled(Section)`
  background-color: ${theme.lightBackgroundColor};
  @media (min-width: ${theme.demoBreakpoint}) {
    display: flex;
  }

  line-height: 1.2;

  ${SectionContent} {
    position: relative;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 1200px) {
      align-items: flex-start;
    }
  }

  p {
    max-width: 400px;
  }
`;

const AboutPage = () => (
  <Layout header={<Header variant="dark" />} footer={<Footer />}>
    <ReactHelmet title="About" />
    <StaticQuery
      query={graphql`
        query AboutImagesQuery {
          duck: file(relativePath: { eq: "big-duck.png" }) {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 604, height: 431)
            }
          }
        }
      `}
      render={data => (
        <SectionContainer>
          <DuckImage fixed={data.duck.childImageSharp.fixed} />
          <H2>Our Story</H2>
          <p>
            As developers at{" "}
            <CopyrightLink href="https://techery.io/" target="_blank" rel="noopener noreferrer">
              Techery
            </CopyrightLink>
            , we’ve built many apps across all mobile platforms over the last years. However, we found a lack of good
            tools for remote application debugging and monitoring.
          </p>
          <p>Countless hours could have been saved with just one proper tool.</p>
          <p>
            That’s why we decided to build <b>AppSpector</b> — to provide the best remote debugger on the market, with
            the options and features we all need for a better debugging experience.
          </p>
          <p>We hope you will love our tool as much as we do!</p>
          <p>Serge Zenchenko - CEO @ AppSpector</p>
          <i>
            Have question? Drop me a line at <b>zen@appspector.com</b>
          </i>
        </SectionContainer>
      )}
    />
    <ContactSection />
  </Layout>
);

export default AboutPage;
